export const API_BASE_PATH = window.location.origin;

export const API_PATH = {
  LOGIN: "/api/v1/login/",
  POST_SIGNUP: `/api/v1/signup/`,
  GET_TERMS_AND_CONDITIONS: "/modules/terms-and-conditions/",
  GET_SUBSCRIPTION_PLAN_DETAILS:"/modules/subscription/get_subscription_plans/",
  GET_PRIVACY_POLICY: "/modules/privacy-policy/",
  GET_FAQS: "/api/v1/faq/",
  POST_MESSAGE_CONTACTUS: "/api/v1/contact-us/",
  BUY_SUBSCRIPTION:'/modules/subscription/buy_subscription_plan/',
  SOCIAL_LOGIN_GOOGLE:'/modules/social-auth/google/login/',
  SOCIAL_LOGIN_FACEBOOK:'/modules/social-auth/facebook/login/'
};