import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from './CheckoutForm'
import {STRIPE_API_KEY} from '../../utils/constants'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './index.module.scss'

const stripePromise = loadStripe(STRIPE_API_KEY)
const Payment = ({
    paymentIntent,
    handleCancelPayment
}) => {
    const [clientSecret, setClientSecret] = useState(paymentIntent);
    const [open, setOpen] = useState(false)
    const appearance = {
        theme: 'stripe',
      };
    const options = {clientSecret,appearance};

    const handleCancel = ()=>{
        setOpen(false)
        handleCancelPayment()
    }

    useEffect(()=>{
        if(clientSecret){
            setOpen(true)
        }
    },[clientSecret])
    
    return ( 
            <>
            {clientSecret && (
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={styles.heading}
                style={{
                    fontSize:`32px`,
                    lineHeight:`38px`,
                }}>
                Buy Subscription
                </DialogTitle>
                <DialogContent>
                    <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm paymentIntent={paymentIntent}/>
                    </Elements>
                </DialogContent>
            </Dialog>
            )}
            </>
        );
        

}
 
export default Payment;