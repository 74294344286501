export const ASSETS = {
  AppLogo: "../assets/AppLogo.svg"
}

export const routeUrls = {
  landingPage: "/",
  signUpPage: "/signup",
  loginPage: "/login",
  signUpComplete: "/signup-complete",
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
  signupSubscription: "/signup-subscription",
  articlecreate: "/create-article/:category/",
  articleView: "/article/:id",
  articleEdit: "/edit-article/:id",
  articleList: "/article-list",
  news: "/news"
}

export const apiUrls = {
  articleFilter: "/api/v1/article-filters/",
  articleImage: "/api/v1/article-image/",
  article: "/api/v1/articles/"
}

export const validCategories = ["sleep", "nutrition"]

export const STRIPE_API_KEY =
  "pk_test_51MlXrhBqf4qNuaUc8k9lFgcuaa1sUqhpTUDWtydgfsZjYcpubUv6PK3Kv4Bgdql1txPTa72LkLhPv0NqiNcmMkMn00w97iBbWo"
export const STRIPE_RETURN_URL =
  window.location.origin + routeUrls.signUpComplete

export const NEWSAPI_KEY = "528e436b5c50482a9f8ab7f228941726"
export const NEWSAPI_URL = `https://newsapi.org/v2/everything?q=&apiKey=${NEWSAPI_KEY}`
