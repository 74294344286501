import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import {STRIPE_RETURN_URL} from '../../utils/constants'
import Loader from "../../molecule/Loader";

const CheckoutForm = ({
    paymentIntent
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
          return;
        }
    
        const clientSecret = paymentIntent;
    
        if (!clientSecret) {
          return;
        }
    
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
          switch (paymentIntent.status) {
            case "succeeded":
              setMessage("Payment succeeded!");
              break;
            case "processing":
              setMessage("Your payment is processing.");
              break;
            case "requires_payment_method":
              setMessage("Your payment was not successful, please try again.");
              break;
            default:
              setMessage("Something went wrong.");
              break;
          }
        });
      }, [stripe]);
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        setIsLoading(true);
    
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: STRIPE_RETURN_URL,
          },
        });
    
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
    
        setIsLoading(false);
      };
    
      const paymentElementOptions = {
        layout: "tabs"
      }

    return ( 
    <form id="payment-form" onSubmit={handleSubmit}>
        <Loader open={isLoading} />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit"
       variant="contained"
       style={{
         width: "21.5625rem",
         backgroundColor: "#FFC200",
         borderRadius: "12.5rem",
         color: "#223C6F",
         fontSize: "1.25rem",
         fontWeight: "700",
         padding: '5px'         
       }}
      >
        <span id="button-text">
          Pay Now
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message"
      style={{
        color:'#FF0000',
        fontSize: '0.75rem'
      }}
      >{message}</div>}
    </form>
     );
}
 
export default CheckoutForm;