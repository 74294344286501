import { Box, Grid } from "@mui/material";

import React from "react";
import styled from "@emotion/styled";
import { scaleByRatio } from "../utils/helper";
import { ReactComponent as Logo } from "../assets/Logo.svg";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { routeUrls } from "../utils/constants";

import "./LandingPageHeader.css";

const StyledLogo = styled(Logo)({
  // height: `${scaleByRatio(100)}px`,
  // width: `${scaleByRatio(209)}px`,
  height: "6.25rem",
  width: "13.0625rem",
});

const LandingPageHeader = ({ customStyle = {} }) => {
  const navigate = useNavigate();
  return (
    // <Box>
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      display={"flex"}
      wrap="nowrap"
      sx={{
        backgroundColor: "",
        width: "100%",
        height: "6.5rem",
        ...customStyle,
      }}
    >
      <Grid item pl={2}>
        <StyledLogo onClick={() => navigate(routeUrls.landingPage)} />
      </Grid>
      <Grid item pr={2} sx={{ display: "flex", gap: "1rem" }}>
        <Button
          label="Log In"
          variant="contained"
          onClick={() => navigate(routeUrls.loginPage)}
          sx={{ width: "10rem", height: "3rem" }}
          className="landing-header-button"
        />
        <Button
          label="Sign Up"
          variant="contained"
          onClick={() => navigate(routeUrls.signUpPage)}
          sx={{ width: "10rem", height: "3rem" }}
          className="landing-header-button"
        />
      </Grid>
    </Grid>
    // </Box>
  );
};

export default LandingPageHeader;
