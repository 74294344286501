import React, { useState, useEffect } from 'react'
import AppleStore from '../../assets/AppleStore.svg'
import GooglePlay from '../../assets/GooglePlay.svg'
import styles from './index.module.scss'
import SignupPageLayout from '../../Layout/SignupPageLayout'
import { Box, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { scaleByRatio } from '../../utils/helper'
import {ButtonVariant, ButtonType, CustomButton} from '../../atoms/CustomButton'
import {localStorageKeys, useLocalStorage} from '../../utils/localStorageItems'
import { useNavigate } from "react-router-dom";
import { routeUrls } from '../../utils/constants'

const SignUpComplete = () => {
    const local_storage = useLocalStorage()
    const nav = useNavigate()
    useEffect(()=>{
        if(!(local_storage.getLocalStorageItem(localStorageKeys.accessToken) && local_storage.getLocalStorageItem(localStorageKeys.user))){
            nav(routeUrls.signUpPage)
        }
    },[])

    const backButtonClick = ()=>{
        nav(routeUrls.signUpPage)
      }

    return ( 
        <SignupPageLayout backButtonClick={backButtonClick}>
            <Grid item xs={12} sm={4}
            sx={{
                backgroundColor:'#053D70',
                borderRadius: `24px 24px 0px 0px`,
                paddingLeft:{xs:'49px',sm:`${scaleByRatio(49)}px`, xl:'49px'},
                paddingRight:{xs:'49px',sm:`${scaleByRatio(49)}px`, xl:'49px'},
                paddingTop:{xs:'24px', sm:`${scaleByRatio(24)}px`, xl:'24px'},
                marginTop: {xs:`40px`, sm:'none', xl:`40px`}
            }}
            >
                <Grid item xs={12} justifyContent={"left"} alignItems={"start"}
                sx={{
                    marginBottom:{xs:'16px', sm:`${scaleByRatio(16)}px`,xl:'16px'},
                }}
                >
                    <Typography className={styles.signUpHeading} 
                    sx={{ 
                        flexGrow: 1, 
                        fontSize:{xs:'28px', sm:`${scaleByRatio(28)}px`,xl:'28px'},
                    }}>
                        THANK YOU !
                    </Typography>
                </Grid>
                <Grid item xs={12} justifyContent={"left"} alignItems={"start"}
                sx={{
                    marginTop:{xs:'16px', sm:`${scaleByRatio(16)}px`,xl:'16px'},
                }}
                >
                    <Typography className={styles.completeDescription} 
                    sx={{ 
                        flexGrow: 1, 
                        fontSize:{xs:'20px', sm:`${scaleByRatio(20)}px`, xl:'20px'},
                        lineHeight: {xs:'32px', sm:`${scaleByRatio(32)}px`, xl:'32px'},
                    }}>
                        Welcome to SlimMed! We're excited to come alongside you in this process! To get started, please download our mobile app!
                    </Typography>
                </Grid>
                <Grid item xs={12} justifyContent={"center"} alignItems={"start"}
                sx={{
                    marginTop:{xs:'40px', sm:`${scaleByRatio(40)}px`, xl:'40px'},
                }}
                >
                    <Typography xs={6} className={styles.completeDescription} 
                    sx={{ 
                        flexGrow: 1, 
                        fontSize:{xs:'14px',sm:`${scaleByRatio(14)}px`, xl:'14px'},
                        lineHeight: {xs:'20px',sm:`${scaleByRatio(20)}px`, xl:'20px'},
                        textAlign:'right',
                        color:'#FFC200',
                        float:'left',
                        width: '50%'
                    }}>
                        Download &nbsp;
                    </Typography>
                    <Typography className={styles.completeDescription} 
                    sx={{ 
                        flexGrow: 1, 
                        fontSize:{xs:'14px',sm:`${scaleByRatio(14)}px`, xl:'14px'},
                        lineHeight: {xs:'20px',sm:`${scaleByRatio(20)}px`, xl:'20px'},
                        textAlign:'left',
                    }}>
                        our app now from
                    </Typography>
                </Grid>
                <Grid item xs={12} justifyContent={"center"} alignItems={"start"}
                sx={{
                    marginTop:{xs:'40px',sm:`${scaleByRatio(40)}px`, xl:'40px'},
                }}
                >
                    <Typography xs={6} className={styles.completeDescription} 
                    sx={{ 
                        flexGrow: 1, 
                        textAlign:'right',
                        float:'left',
                        width: '49%',
                        marginRight:`2px`
                    }}>
                        <Box component="img"  src={GooglePlay} alt="Google play store" 
                        sx={{
                            width:{xs:'150px',sm:`${scaleByRatio(150)}px`,xl:'150px'}
                        }}
                        />
                    </Typography>
                    <Typography className={styles.completeDescription} 
                    sx={{ 
                        flexGrow: 1, 
                        textAlign:'left',
                        //padding:`${scaleByRatio(12.5)}px`,
                        marginLeft:`2px`
                    }}>
                        <Box component="img"  src={AppleStore} alt="Google play store"
                        sx={{
                            width:{xs:'150px',sm:`${scaleByRatio(150)}px`,xl:'150px'}
                        }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12} justifyContent={"center"} sx={{
                        display:'flex',
                        marginTop:{xs:'40px',sm:`${scaleByRatio(40)}px`, xl:'40px'}
                    }}>
                        <Typography textAlign='center' fullWidth
                        sx={{
                            flexGrow: 1, 
                        }}>
                            <CustomButton 
                                text={'GO BACK TO HOME'}
                                variant={ButtonVariant.contained}
                                type={ButtonType.custom}
                                onClick={()=>nav(routeUrls.landingPage)}
                            />
                        </Typography>
                    </Grid>
                
                

            </Grid>
        </SignupPageLayout>
     );
}
 
export default SignUpComplete;