import React, { useState } from 'react'
import { LoginSocialGoogle, LoginSocialApple, LoginSocialFacebook } from 'reactjs-social-login'
import { useNavigate } from 'react-router-dom'
import AppleIcon from "../../assets/AppleIcon.svg";
import GoogleIcon from "../../assets/GoogleIcon.svg";
import FacebookIcon from "../../assets/FacebookIcon.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { scaleByRatio } from "../../utils/helper";
import styles from "./index.module.scss";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import {API_BASE_PATH} from '../../utils/config'
import { useSocialLoginGoogle, useSocialLoginFacebook } from "../../services/mutations";
import {
    localStorageKeys,
    useLocalStorage,
  } from "../../utils/localStorageItems";
import Loader from "../../molecule/Loader";
import { routeUrls } from "../../utils/constants";


//const googleClientID = '631770886449-mfupvpgaj7fmblkmoiqubrpa91ige5s0.apps.googleusercontent.com'
const googleClientID = '701393502745-n00k67509fep03agv7t48agng3lf1acl.apps.googleusercontent.com'
const appleClientID = 'com.sapienthealth.services'
const facebookAppId = '376524628187535'

const SocialLogin = () => {
    let socialLoginGoogle = useSocialLoginGoogle();
    let socialLoginFacebook = useSocialLoginFacebook()
    const local_storage = useLocalStorage();
    const nav = useNavigate();

    const [loading, setLoading] = useState(false);

    const responseGoogle = (response) => {
        console.log("responseGoogle:: ", response)
        let access_token = response?.data?.access_token
        console.log("access_token: ", access_token)
        let data = {
            "access_token": access_token,
            "code": access_token
        }
        setLoading(true)
        socialLoginGoogle.mutate(data,{
            onSuccess:(response)=>{
                setLoading(false);
                local_storage.setLocalStorageItem(
                    localStorageKeys.accessToken,
                    response?.data?.token
                );
                local_storage.setLocalStorageItem(
                    localStorageKeys.is_pre_appointment_filled,
                    response?.data?.is_pre_appointment_filled
                );
                local_storage.setLocalStorageItem(
                    localStorageKeys.user,
                    JSON.stringify(response?.data?.user)
                );
                let is_subscribed = response?.data?.is_subscribed || false;
                if(is_subscribed){
                    toast.error("User is already registered with this e-mail address.")
                }else{
                    nav(routeUrls.signupSubscription);
                }
                console.log('Google login success:',response)
                

            },
            onError:(response)=>{
                setLoading(false);
                console.log("error response:",response?.response?.data?.non_field_errors[0])
                toast.error(response?.response?.data?.non_field_errors[0] || 'Something went wrong. Please try again after sometime.')
            }
        })
    };
    const responseApple = (response) => {
        console.log('responseApple:: ',response)
        
    }
    const responseFacebook = (response) => {
        console.log('responseFacebook:: ',response)
        let access_token = response?.data?.accessToken
        console.log("access_token: ", access_token)

        let data = {
            "access_token": access_token,
            "code": access_token
        }
        setLoading(true)
        socialLoginFacebook.mutate(data,{
            onSuccess:(response)=>{
                setLoading(false);
                local_storage.setLocalStorageItem(
                    localStorageKeys.accessToken,
                    response?.data?.token
                );
                local_storage.setLocalStorageItem(
                    localStorageKeys.is_pre_appointment_filled,
                    response?.data?.is_pre_appointment_filled
                );
                local_storage.setLocalStorageItem(
                    localStorageKeys.user,
                    JSON.stringify(response?.data?.user)
                );
                let is_subscribed = response?.data?.is_subscribed || false;
                if(is_subscribed){
                    toast.error("User is already registered with this e-mail address.")
                }else{
                    nav(routeUrls.signupSubscription);
                }
                console.log('Facebook login success:',response)

            },
            onError:(response)=>{
                setLoading(false);
                console.log("error response:",response?.response?.data?.non_field_errors[0])
                toast.error(response?.response?.data?.non_field_errors[0] || 'Something went wrong. Please try again after sometime.')
            }
        })
    }

    return ( 
        <> 
        <Loader open={loading} />
        <Typography
        textAlign="center"
        xs={12}
        justifyItems={"center"}
        display={"flex"}
        justifyContent={"space-evenly"}
        sx={{
            mt: {xs:4, sm:2},
            gap: {xs:'80px',sm:`${scaleByRatio(80)}px`, xl:`80px`},
            //display:{xs:'none', sm:'flex'}
        }}
        >
            <LoginSocialGoogle
            client_id={googleClientID}
            isOnlyGetToken={true}
            scope='openid profile email'
            discoveryDocs='claims_supported'
            onResolve={responseGoogle}
            onReject={(err) => {
                console.log(err)
            }}
            >
                <Box component="img" 
                    src={GoogleIcon}
                    alt="google"
                    className={styles.appIcon}
                    sx={{
                        height: {xs:'50px',sm:`${scaleByRatio(50)}px`, xl:'50px'},
                        width: {xs:'50px',sm:`${scaleByRatio(50)}px`, xl:'50px'},
                        cursor:'pointer'
                    }}
                />
            </LoginSocialGoogle>
            <LoginSocialApple
            client_id={appleClientID}
            redirect_uri={`${API_BASE_PATH}/accounts/apple/login/callback/`}
            scope='name email'
            onResolve={responseApple}
            onReject={(err) => {
                console.log(err)
            }}
            >
                <Box component="img"
                    src={AppleIcon}
                    alt="google"
                    className={styles.appIcon}
                    sx={{
                        height: {xs:'50px',sm:`${scaleByRatio(50)}px`, xl:'50px'},
                        width: {xs:'50px',sm:`${scaleByRatio(50)}px`, xl:'50px'},
                        cursor:'pointer'
                    }}
                />
            </LoginSocialApple>
            <LoginSocialFacebook
            appId={facebookAppId}
            isOnlyGetToken={true}
            fieldsProfile={
                'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
            }
            onResolve={responseFacebook}
            onReject={err => {
                console.log(err);
            }}
            >
                <Box component="img"
                    src={FacebookIcon}
                    alt="google"
                    className={styles.appIcon}
                    sx={{
                        height: {xs:'50px',sm:`${scaleByRatio(50)}px`, xl:'50px'},
                        width: {xs:'50px',sm:`${scaleByRatio(50)}px`, xl:'50px'},
                        cursor:'pointer'
                    }}
                />
            </LoginSocialFacebook>
        </Typography>
      </>

     );
}
 
export default SocialLogin;