import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { TextField, Button, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Select, MenuItem } from '@mui/material';
import Header from '../../organism/header';
import './styles.css'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { apiUrls, routeUrls, validCategories } from '../../utils/constants';
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const CreateArticle = () => {
  const { category } = useParams();
  const navigate = useNavigate();

  const [articleImage, setArticleImage] = useState();
  const [sectionImages, setSectionImages] = useState([]);

  const [filters, setFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [filterError, setFilterError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrls.articleFilter);
        setFilters(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
    setFilterError('');
  };

  const uploadImageToAPI = async (imageFile) => {
    const formData = new FormData();
    formData.append('image', imageFile);

    try {
      const response = await axios.post(apiUrls.articleImage, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      titleSub: '',
      intro: '',
      articleImage: null,
      sections: [{ heading: '', content: '', contentType: 'paragraph' }]
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Title is required'),
      titleSub: Yup.string().required('Subtitle is required'),
      intro: Yup.string().required('Introduction is required'),
      articleImage: Yup.mixed().required('Article image is required'),
      sections: Yup.array().of(Yup.object().shape({
        heading: Yup.string().required('Section heading is required'),
        content: Yup.string().required('Section content is required')
      }))
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!selectedFilter && !(category == 'sleep')) {
        setFilterError('Filter is required');
        toast.warn("Please select filter")
        return;
      }

      try {
        // Upload article image
        const uploadedArticleImageResponse = await uploadImageToAPI(articleImage);
        const uploadedArticleImageURL = uploadedArticleImageResponse.id;

        // Upload section images
        const sectionImageURLs = [];
        for (let i = 0; i < sectionImages.length; i++) {
          if (sectionImages[i]) {
            const uploadedSectionImageResponse = await uploadImageToAPI(sectionImages[i]);
            sectionImageURLs.push(uploadedSectionImageResponse.id);
          } else {
            sectionImageURLs.push(null);
          }
        }

        const content = {
          "uploadedArticleImageURL": uploadedArticleImageURL,
          "title": values.title,
          "titleSub": values.titleSub,
          "intro": values.intro,
          "section": values.sections.map(section => {
            const sectionCopy = { ...section };
            delete sectionCopy.image;
            return sectionCopy;
          }),
          "sectionImageURLs": sectionImageURLs
        }

        const formData = new FormData();
        formData.append('name', values.title);
        formData.append('sub_title',values.titleSub)
        formData.append('category', category);
        if(!(category == 'sleep')){
          formData.append('filters', [selectedFilter]);
        }
        formData.append('content', JSON.stringify(content));
        formData.append('is_active', true);

        const response = await axios.post(apiUrls.article, formData);
        toast.success("Article posted successfully")

        navigate(routeUrls.articleList)
      } catch (error) {
        console.error('Error submitting article:', error);
        toast.error("Error submitting article")
      }

    }

  });

  const handleArticleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formik.setFieldValue('articleImage', reader.result);
        setArticleImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSectionImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newSectionImages = [...sectionImages];
        newSectionImages[index] = file;
        setSectionImages(newSectionImages);
        formik.setFieldValue(`sections[${index}].image`, reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveSection = (index) => {
    const newSectionImages = [...sectionImages];
    newSectionImages.splice(index, 1);
    setSectionImages(newSectionImages);

    const newSections = [...formik.values.sections];
    newSections.splice(index, 1);
    formik.setFieldValue('sections', newSections);
  };

  return (
    <>
      <Header />
      {!validCategories.includes(category) ? <></> :
        <div className='formContainer'>
          <Button onClick={() => navigate(-1)} style={{ color: 'blue', left: '0px' }}>&#8592; Back</Button>
          <div className='categoryHeading centerAlignedContainer'>{category.toUpperCase()}</div>
          
          <br />
          <form onSubmit={formik.handleSubmit}>

            <div className="centerAlignedContainer">
              {formik.values.articleImage && (
                <img src={formik.values.articleImage} alt={`Article Image`} className='articleImage' />
              )}
            </div>
            <div className="centerAlignedContainer">
              <input
                accept="image/*"
                id="article-image-upload"
                type="file"
                style={{ display: 'none' }}
                onChange={handleArticleImageChange}
              />
              <label htmlFor="article-image-upload">
                <Button variant="contained" component="span" style={{ margin: '10px 0px' }}>
                  Add Article Image*
                </Button>
              </label>
              {formik.touched.articleImage && formik.errors.articleImage && <div className="error">{formik.errors.articleImage}</div>}
              { !(category == 'sleep') && <FormControl>
                <Select
                  value={selectedFilter}
                  onChange={handleFilterChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select filter' }}
                >
                  <MenuItem value="" disabled>
                    Select Filter*
                  </MenuItem>
                  {filters.map((filter) => (
                    <MenuItem key={filter.id} value={filter.id}>
                      {filter.name}
                    </MenuItem>
                  ))}
                </Select>
                {filterError && <div className="error">{filterError}</div>}
              </FormControl>}
            </div>

            <TextField
              name="title"
              label="Title*"
              value={formik.values.title}
              onChange={formik.handleChange}
              fullWidth
              margin="normal"
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              inputProps={{ maxLength: 32 }}
            />
            <TextField
              name="titleSub"
              label="Subtitle*"
              value={formik.values.titleSub}
              onChange={formik.handleChange}
              fullWidth
              margin="normal"
              error={formik.touched.titleSub && Boolean(formik.errors.titleSub)}
              helperText={formik.touched.titleSub && formik.errors.titleSub}
              inputProps={{ maxLength: 42 }}
            />



            <TextField
              type='text'
              name="intro"
              label="Introduction*"
              value={formik.values.intro}
              onChange={formik.handleChange}
              fullWidth
              margin="normal"
              multiline
              minRows={4}
              error={formik.touched.intro && Boolean(formik.errors.intro)}
              helperText={formik.touched.intro && formik.errors.intro}
            />

            {formik.values.sections.map((section, index) => (
              <div key={index} className='centerAlignedContainer'>
                <TextField
                  name={`sections[${index}].heading`}
                  label="Section Heading*"
                  value={section.heading}
                  onChange={formik.handleChange}
                  fullWidth
                  margin="normal"
                  error={formik.touched.sections && formik.touched.sections[index] && Boolean(formik.errors.sections?.[index]?.heading)}
                  helperText={formik.touched.sections && formik.touched.sections[index] && formik.errors.sections?.[index]?.heading}
                />
                <FormControl component="fieldset">
                  <FormLabel component="legend">Section Content Type</FormLabel>
                  <RadioGroup
                    row
                    aria-label="content-type"
                    name={`sections[${index}].contentType`}
                    value={section.contentType}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel value="paragraph" control={<Radio />} label="Paragraph" />
                    <FormControlLabel value="list" control={<Radio />} label="List" />
                  </RadioGroup>
                </FormControl>

                <TextField
                  name={`sections[${index}].content`}
                  label="Section Content*"
                  value={section.content}
                  onChange={formik.handleChange}
                  fullWidth
                  margin="normal"
                  multiline
                  minRows={4}
                  error={formik.touched.sections && formik.touched.sections[index] && Boolean(formik.errors.sections?.[index]?.content)}
                  helperText={formik.touched.sections && formik.touched.sections[index] && formik.errors.sections?.[index]?.content}
                />
                {sectionImages[index] && (
                  <img src={formik.values.sections[index].image} alt={`Section ${index + 1} Image`} className='sectionImage' />
                )}
                <input
                  accept="image/*"
                  id={`section-image-upload-${index}`}
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    handleSectionImageChange(e, index);
                  }}
                />
                <div>
                  <label htmlFor={`section-image-upload-${index}`}>
                    <Button component="span">
                      Add Section Image
                    </Button>
                  </label>

                  <Button type="button" onClick={() => handleRemoveSection(index)} style={{ color: 'red' }}>Remove Section</Button>
                </div>

              </div>
            ))}
            <div className="centerAlignedContainer bootonButtons">
              <Button type="button" onClick={() => formik.setFieldValue('sections', [...formik.values.sections, { heading: '', content: '', contentType: 'paragraph' }])}>Add Section</Button>
              <br />
              <Button variant="contained" type="submit" style={{ backgroundColor: '#ffc200', color: 'black' }}>Submit</Button>
            </div>


          </form>
        </div>
      }
    </>
  );
}

export default CreateArticle;
