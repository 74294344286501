import { Box, Collapse, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const FAQs = ({ QNAs }) => {
  let arr = new Array(QNAs?.length);
  const initializedArrayToHandleCollapsible = [...arr.fill(false)];

  const [showAnswer, setShowAnswer] = useState(
    initializedArrayToHandleCollapsible
  );

  const toggleShowAnswer = (index) => {
    setShowAnswer((showAnswer) => {
      const updatedShowAnswer = [...showAnswer];
      updatedShowAnswer[index] = !updatedShowAnswer[index];
      return updatedShowAnswer;
    });
    // console.log(showAnswer);
  };
  return (
    <Grid
      container
      // mb={3}
      p={3}
      color="#053D70"
      sx={{
        width: "70%",
        boxShadow: "0 0.5rem 1rem 0 #0000001F",
        margin: "0 auto 0 auto",
        borderRadius: "1.25rem",
        backgroundColor: "#ffffff",
      }}
    >
      <Grid item>
        <Typography pb={1} color="#111111" fontWeight="700" fontSize="1.25rem">
          FAQs
        </Typography>
      </Grid>

      <Grid item>
        <Typography fontWeight="400" mb={3} fontSize="1.25rem">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          quis eros posuere, fermentum odio quis, elementum odio. Duis sagittis
          viverra purus sed egestas.
        </Typography>
      </Grid>
      <Grid item>
        {QNAs?.map((item, index) => (
          <Grid container justifyContent="center" spacing={1}>
            <Grid item md={1} xs={2} paddingTop={{ xs: 1 }}>
              {showAnswer[index] ? (
                <RemoveIcon
                  style={{
                    // height: "1.5rem",
                    // width: "1.5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleShowAnswer(index)}
                />
              ) : (
                <AddIcon
                  style={{
                    // height: "1.5rem",
                    // width: "1.5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => toggleShowAnswer(index)}
                />
              )}
            </Grid>
            <Grid item xs={10} md={11} p={3}>
              <Typography display="inline" pl={1} sx={{ fontSize: "1rem" }}>
                {item?.question}
              </Typography>
              <Collapse in={showAnswer[index]}>
                <Box
                  ml={{ md: 5, xs: 3 }}
                  p={1}
                  sx={{ borderLeft: "3px solid #FFC200" }}
                >
                  <Typography sx={{ fontSize: "0.875rem" }}>
                    {item?.answer}
                  </Typography>
                </Box>
              </Collapse>
            </Grid>
          </Grid>
          // </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default FAQs;
