import React from "react"
import { createBrowserRouter } from "react-router-dom"
import { routeUrls } from "../constants"
import LandingPage from "../../Pages/LandingPage"
import SignUp from "../../Pages/SignUp"
import SignUpComplete from "../../Pages/SignUpComplete"
import TermsAndConditions from "../../Pages/TermsAndConditions"
import PrivacyPolicy from "../../Pages/PrivacyPolicy"
import SignupSubscription from "../../Pages/SignupSubscription"
import CreateArticle from "../../Pages/Articles/CreateArticle"
import ViewArticle from "../../Pages/Articles/ViewArticle"
import EditArticle from "../../Pages/Articles/EditArticle"
import ArticleList from "../../Pages/Articles/ArticleList"
import LogIn from "../../Pages/LogIn"
import useAuth from "../Auth"
import { useNavigate } from "react-router-dom"
import NewsList from "../../Pages/News/NewsList"

const ProtectedRoute = ({ element, path }) => {
  const { isUserLoggedIn } = useAuth()
  console.log("check", isUserLoggedIn())
  if (isUserLoggedIn()) {
    return element
  } else {
    return <LandingPage />
  }
}

const routes = [
  {
    path: routeUrls.landingPage,
    element: <LandingPage />
  },
  {
    path: routeUrls.signUpPage,
    element: <SignUp />
  },
  {
    path: routeUrls.loginPage,
    element: <LogIn />
  },
  {
    path: routeUrls.signupSubscription,
    element: <SignupSubscription />
  },
  {
    path: routeUrls.signUpComplete,
    element: <SignUpComplete />
  },
  {
    path: routeUrls.termsAndConditions,
    element: <TermsAndConditions />
  },
  {
    path: routeUrls.privacyPolicy,
    element: <PrivacyPolicy />
  },
  {
    path: routeUrls.articlecreate,
    element: <ProtectedRoute element={<CreateArticle />} />
  },
  {
    path: routeUrls.articleView,
    element: <ViewArticle />
  },
  {
    path: routeUrls.articleEdit,
    element: <ProtectedRoute element={<EditArticle />} />
  },
  {
    path: routeUrls.articleList,
    element: <ProtectedRoute element={<ArticleList />} />
  },
  {
    path: routeUrls.news,
    element: <ProtectedRoute element={<NewsList />} />
  }
]

const router = createBrowserRouter(routes)

export default router
