import React from "react"
import AppLogo from "../../assets/AppLogo.svg"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { Box, Button } from "@mui/material"
import styles from "./index.module.scss"
import { scaleByRatio } from "../../utils/helper"
import { routeUrls } from "../../utils/constants"
import { Link, useNavigate } from "react-router-dom"
import {
  useLocalStorage,
  localStorageKeys
} from "../../utils/localStorageItems"
import useAuth from "../../utils/Auth"

const Header = () => {
  const nav = useNavigate()
  const { removeLocalStorageItem, getLocalStorageItem } = useLocalStorage()
  const { isUserLoggedIn } = useAuth()

  const handleLogout = () => {
    removeLocalStorageItem(localStorageKeys.accessToken)
    nav(routeUrls.landingPage)
  }

  const userName = JSON.parse(getLocalStorageItem(localStorageKeys.user))?.name

  return (
    <Box>
      <AppBar className={styles.header} component="nav">
        <Toolbar sx={{ height: { xs: `${scaleByRatio(100)}px`, xl: `100px` } }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, minHeight: `${scaleByRatio(100)}px` }}
          >
            <img
              src={AppLogo}
              alt="App Icon"
              className={styles.appIcon}
              sx={{
                height: { xs: `${scaleByRatio(100)}px`, xl: `100px` },
                width: { xs: `${scaleByRatio(209)}px`, xl: `209px` },
                cursor: "pointer"
              }}
              onClick={() => nav(routeUrls.landingPage)}
            />
          </Typography>
          {isUserLoggedIn() && ( // Conditionally render logout button
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Link to={routeUrls.articleList}>
                <Typography variant="body1" color="black">
                  Articles
                </Typography>
              </Link>{" "}
              <Typography variant="body1" color="black">
                &nbsp;&nbsp; | &nbsp;&nbsp;
              </Typography>
              <Link to={routeUrls.news} className={styles.link}>
                <Typography variant="body1" color="black">
                  News
                </Typography>
              </Link>{" "}
              <Typography variant="body1" color="black">
                &nbsp;&nbsp; | &nbsp;&nbsp;
              </Typography>
              <Typography variant="body1" color="black">
                {userName}
              </Typography>{" "}
              &nbsp;&nbsp;
              <Button
                sx={{ backgroundColor: "#ffe600", color: "black" }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header
