import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable } from 'react-table';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import './styles.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { apiUrls } from '../../utils/constants';
import axios from 'axios';

const TableArticle = ({ data, fetchData, setCurrentPage }) => {
    const navigate = useNavigate();

    const handleDelete = async (id) => {
        try {
            await axios.delete(apiUrls.article + id + "/");
            fetchData();
            setCurrentPage(1);
            console.log(`Article with ID ${id} deleted successfully.`);
        } catch (error) {
            console.error('Error deleting article:', error);
        }
    };

    const handleToggleActive = async (id, isActive) => {
        try {
            const formData = new FormData();
            formData.append('is_active', !isActive);
            await axios.patch(apiUrls.article + id + "/", formData);
            fetchData();
            setCurrentPage(1);
        } catch (error) {
            console.error('Error toggling active status:', error);
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Category',
                accessor: 'category',
                Cell: ({ value }) => {
                    // Capitalize the first letter of each word in the category
                    return value.charAt(0).toUpperCase() + value.slice(1);
                },
            },
            {
                Header: 'Created At',
                accessor: 'created_at',
            },
            {
                Header: 'Updated At',
                accessor: 'updated_at',
            },
            {
                Header: 'Active',
                accessor: 'is_active',
                Cell: ({ row }) => (
                    <div className="toggleButton">
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={row.original.is_active}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggleActive(row.original.id, row.original.is_active);
                                }}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                ),
            },
            {
                Header: 'Actions',
                Cell: ({ row }) => (
                    <div>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/edit-article/${row.original.id}`);
                        }}>
                            <EditIcon />
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(row.original.id);
                        }}>
                            <DeleteIcon />
                        </button>
                    </div>
                ),
            },
        ],
        []
    );

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <div className='tableContainer'>
            <Table {...getTableProps()} className="table"> {/* Applying the 'table' class */}
                <TableHead>
                    {headerGroups.map(headerGroup => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <TableRow {...row.getRowProps()}
                                onClick={() => navigate(`/article/${row.original.id}`,
                                    {
                                        state: { showBack: true }
                                    })}
                            >
                                {row.cells.map(cell => (
                                    <TableCell {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>

    );
};

export default TableArticle;
