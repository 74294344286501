import React, { useState } from "react";
import styles from "./index.module.scss";
import SignupPageLayout from "../../Layout/SignupPageLayout";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { scaleByRatio } from "../../utils/helper";
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from "../../atoms/CustomButton";
import { useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useLogin } from "../../services/mutations";
import { routeUrls } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import Loader from "../../molecule/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  localStorageKeys,
  useLocalStorage,
} from "../../utils/localStorageItems";
import TextField from "@mui/material/TextField";
import btnBackNoBackground from "../../assets/btnBackNoBackground.svg";

import "react-phone-input-2/lib/style.css";

const LogIn = () => {
  const nav = useNavigate();
  const logIn = useLogin();
  const local_storage = useLocalStorage();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Please enter your email")
        .email("Please enter a valid email"),
   
      password: Yup.string()
        .required("Password is required")
      
    }),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));

     
      setLoading(true);
     
          logIn.mutate(
            { username: values.email, password: values.password },
            {
              onSuccess: (response) => {
                setLoading(false);
                console.log("login response:", response);
                local_storage.setLocalStorageItem(
                  localStorageKeys.accessToken,
                  response?.data?.token
                );
                // local_storage.setLocalStorageItem(
                //   localStorageKeys.is_pre_appointment_filled,
                //   response?.data?.is_pre_appointment_filled
                // );
                local_storage.setLocalStorageItem(
                  localStorageKeys.user,
                  JSON.stringify(response?.data?.user)
                );
                nav(routeUrls.articleList);
              },
              onError: (err) => {
                setLoading(false);
                toast.error(
                  err?.response?.data?.non_field_errors[0] ||
                    "Something went wrong. Please try again."
                );
              },
            }
          );
    },
  });

  const backButtonClick = () => {
    nav(routeUrls.landingPage);
  };

  return (
    <>
      <Loader open={loading} />
      <SignupPageLayout backButtonClick={backButtonClick}>
        <Grid
          item
          sm={4}
          sx={{
            backgroundColor: "#053D70",
            borderRadius: `24px 24px 0px 0px`,
            paddingLeft: { xs: `${scaleByRatio(49)}px`, xl: `49px` },
            paddingRight: { xs: `${scaleByRatio(49)}px`, xl: `49px` },
            paddingTop: { xs: `${scaleByRatio(24)}px`, xl: `24px` },
            display: { xs: `none`, sm: `block` },
          }}
        >
          <Grid
            item
            xs={12}
            justifyContent={"left"}
            alignItems={"start"}
            sx={{
              marginBottom: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
            }}
          >
            <Typography
              className={styles.signUpHeading}
              sx={{
                flexGrow: 1,
                fontSize: { xs: `${scaleByRatio(28)}px`, xl: `28px` },
              }}
            >
              LOG IN
            </Typography>
          </Grid>
          <FormikProvider value={formik}>
            <form>
  
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                  }}
                >
                  EMAIL
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Please enter your email"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.email}
                  onChange={(e) => {
                    const value = e.target.value || "";
                    formik.setFieldValue("email", value.toLowerCase());
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{
                    minHeight: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                    fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                  }}
                />
              </Grid>
              
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                  }}
                >
                  PASSWORD
                  
                </Typography>
                
                <TextField
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  id="password"
                  name="password"
                  placeholder="Please enter your password"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  sx={{
                    minHeight: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                    fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                    },
                    endAdornment: (
                      <div
                        style={{
                          borderRadius: "0%",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          style={{ color: "black", cursor: "pointer" }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                    ),
                  }}
                />
              </Grid>
              <br />
              <Grid
                item
                xs={12}
                justifyContent={"center"}
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <CustomButton
                    text={"LOG IN"}
                    variant={ButtonVariant.contained}
                    type={ButtonType.custom}
                    onClick={formik.handleSubmit}
                  />
                </Typography>
              </Grid>
            </form>
          </FormikProvider>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#053D70",
            borderRadius: `24px 24px 0px 0px`,
            paddingLeft: `20px`,
            paddingRight: `20px`,
            paddingTop: `24px`,
            marginTop: `40px`,
            marginBottom: "20px",
            display: { xs: "block", sm: "none" },
          }}
        >
          <Grid
            item
            xs={12}
            justifyContent={"left"}
            alignItems={"start"}
            sx={{
              marginBottom: `16x`,
            }}
          >
            <Typography
              className={styles.signUpHeading}
              sx={{
                flexGrow: 1,
                fontSize: `28px`,
              }}
            >
              <Box
                component="img"
                src={btnBackNoBackground}
                alt="back"
                className={styles.appIcon}
                sx={{
                  height: { xs: "32px" },
                  width: { xs: "32px" },
                  cursor: "pointer",
                  paddingTop: "7px",
                }}
                onClick={backButtonClick}
              />
              &nbsp;LOG IN
            </Typography>
          </Grid>
          <FormikProvider value={formik}>
            <form>
              
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: `16px`,
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: `16px`,
                  }}
                >
                  EMAIL
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Please enter your email"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.email}
                  onChange={(e) => {
                    const value = e.target.value || "";
                    formik.setFieldValue("email", value.toLowerCase());
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{
                    minHeight: { xs: `40px` },
                    fontSize: { xs: `14px` },
                    textTransform: "lowercase",
                  }}
                  style={{
                    textTransform: "lowercase",
                  }}
                />
                
              </Grid>
              
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: `16px`,
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: `16px`,
                  }}
                >
                  PASSWORD
                  
                </Typography>
                <TextField
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  id="password"
                  name="password"
                  placeholder="Please enter your password"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  sx={{
                    minHeight: { xs: `40px` },
                    fontSize: { xs: `14px` },
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                    },
                    endAdornment: (
                      <div
                        style={{
                          borderRadius: "0%",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          style={{ color: "black", cursor: "pointer" }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                    ),
                  }}
                />
              </Grid>
              <br />
              <Grid
                item
                xs={12}
                justifyContent={"center"}
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <CustomButton
                    text={"LOG IN"}
                    variant={ButtonVariant.contained}
                    type={ButtonType.custom}
                    onClick={formik.handleSubmit}
                  />
                </Typography>
              </Grid>
              <br/><br/>
            </form>
          </FormikProvider>
         
          
        </Grid>
      </SignupPageLayout>
    </>
  );
};

export default LogIn;
