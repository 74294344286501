import React from 'react'
import { useLocalStorage, localStorageKeys } from "./localStorageItems";

const useAuth = () => {
    const { getLocalStorageItem } = useLocalStorage();
    const accessToken = getLocalStorageItem(localStorageKeys.accessToken);

    const isUserLoggedIn = () => {
        console.log('access Token', accessToken);
        return accessToken !== null && accessToken !== undefined;
    };

    return { isUserLoggedIn };
};

export default useAuth