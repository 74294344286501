import React, { useEffect, useState } from 'react';
import Header from '../../organism/header';
import './styles.css';
import axios from 'axios';
import TableArticle from './tableArticle';
import { apiUrls } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

const ArticleList = () => {
    const navigate = useNavigate();

    const [articleData, setArticleData] = useState(null);
    const [loading, setLoading] = useState(false);
    const pageSize = 10; // Define page size
    const [currentPage, setCurrentPage] = useState(1);

    const fetchData = async (pageNumber = 1) => {
        try {
            setLoading(true);
            const response = await axios.get(apiUrls.article, {
                params: {
                    page: pageNumber,
                    page_size: pageSize
                }
            });
            if (response.data) {
                setArticleData(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Initial fetch with default page number
        setCurrentPage(1);
    }, []);

    const handleNextPage = () => {
        if (articleData && articleData.next) {
            const pageNumber = currentPage+1;
            fetchData(pageNumber);
            setCurrentPage(pageNumber);
        }
    };

    const handlePreviousPage = () => {
        if (articleData && articleData.previous) {
            const pageNumber = currentPage-1;
            fetchData(pageNumber);
            setCurrentPage(pageNumber);
        }
    };

    return (
        <>
            <Header />
            <div className='formContainer'>
                <div className="addArticles">
                    <p>Add Article : </p>
                    <div>
                        <button onClick={() => navigate('/create-article/nutrition')}>Nutrition</button>
                        <button onClick={() => navigate('/create-article/sleep')}>Sleep</button>
                    </div>
                </div>
                {loading && <p>Loading...</p>}
                {articleData && <TableArticle data={articleData.results} fetchData={fetchData} setCurrentPage={setCurrentPage}/>}
                <div className="pagination">
                    <button onClick={handlePreviousPage} disabled={!articleData || !articleData.previous}>
                        Previous Page
                    </button>
                    <p> {currentPage} &nbsp;&nbsp;</p>
                    <button onClick={handleNextPage} disabled={!articleData || !articleData.next}>
                        Next Page
                    </button>
                </div>
            </div>
        </>
    );
};

export default ArticleList;
