import React, { useState, useEffect } from "react";
import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import LandingPageLayout from "../../Layout/LandingPageLayout";
import FAQs from "./FAQs";
import ContactUs from "./ContactUs";
import SnapperBackground from "../../assets/First Snapper Background.svg";
import AboutUs from "./AboutUs";
import MockUpsSVG from "../../assets/Mock Ups.svg";
import AppStoreSVG from "../../assets/AppStore.svg";
import GooglePlaySVG from "../../assets/google-play-badge 1.svg";
import { scaleByRatio } from "../../utils/helper";
import { useFAQs } from "../../services/mutations";
import { toast } from "react-toastify";
import useScreenType from "react-screentype-hook";
import Subscription from "./SubscriptionPlans";

const StyledSnapperBackground = styled(SnapperBackground)({
  width: "100%",
  height: "auto",
});

const LandingPage = () => {
  const [QNAs, setQNAs] = useState([]);
  const faqs = useFAQs();

  useEffect(() => {
    faqs.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log(response);
          const data = response?.data;
          setQNAs(data);
        },
        onError: (response) => {
          toast.error(
            response?.message || "Something went wrong. Please try again.",
          );
        },
      },
    );
  }, []);

  return (
    <LandingPageLayout>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Grid
          item
          sx={{
            display: { sx: "block", md: "none" },
            width: "100%",
            height: "100px",
            backgroundColor: "#053D70",
          }}
        ></Grid>
        <Grid xs={12} mt={-1}>
          <img
            src={SnapperBackground}
            alt="App Icon"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Grid>

        <Grid
          container
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          sx={{
            position: "absolute",
            backgroundColor: "",
            top: "4%",
          }}
        >
          <Grid item>
            <Typography
              variant="h2"
              sx={{
                color: "#FFC200",
                fontFamily: "Kiona",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                fontSize: { md: "2rem", xs: "1.8rem" },
              }}
            >
              Transform your life today!
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              textAlign="center"
              color="white"
              sx={{
                zIndex: "1",
                fontFamily: "Fira Sans",
                fontWeight: "500",
                fontSize: "1.5rem",
                lineHeight: { md: "2rem", xs: "1.5rem" },
              }}
            >
              <br />
              <span
                style={{
                  color: "#FFC200",
                  fontFamily: "Fira Sans",
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                }}
              >
                Join SlimMed
              </span>
              <br /> and take the first step towards &nbsp;
              <span
                style={{
                  color: "#FFC200",
                  fontFamily: "Fira Sans",
                  fontWeight: "400",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                }}
              >
                a healthier and fitter you
              </span>
              . It's time to make <br /> a&nbsp;
              <span
                style={{
                  color: "#FFC200",
                  fontFamily: "Fira Sans",
                  fontWeight: "400",
                  fontSize: "1.5rem",
                  lineHeight: "2rem",
                }}
              >
                change
              </span>{" "}
              and we're here to help.
            </Typography>
          </Grid>
          <Grid
            item
            container
            mt={1}
            sx={{
              width: "30%",
              backgroundColor: "",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6} md={6}>
              <img
                src={GooglePlaySVG}
                alt="App Icon"
                style={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <img
                src={AppStoreSVG}
                alt="App Icon"
                style={{
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          sx={{ position: "absolute", top: { md: "65%", xs: "77%" } }}
        >
          <img
            src={MockUpsSVG}
            alt="banner-mob-Icon"
            style={{
              width: "65%",
            }}
          />
        </Grid>
      </Box>
      <AboutUs />
      <Grid container sx={{ position: "relative" }}>
        {console.log(QNAs)}
        <Subscription QNAs={QNAs} />
      </Grid>
    </LandingPageLayout>
  );
};

export default LandingPage;
