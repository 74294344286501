import React, { useState, useEffect } from "react";
import AppleIcon from "../../assets/AppleIcon.svg";
import GoogleIcon from "../../assets/GoogleIcon.svg";
import FacebookIcon from "../../assets/FacebookIcon.svg";
import styles from "./index.module.scss";
import SignupPageLayout from "../../Layout/SignupPageLayout";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { scaleByRatio } from "../../utils/helper";
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from "../../atoms/CustomButton";
import { useFormik, Field, FormikProvider, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useLogin, useSignUp } from "../../services/mutations";
import { routeUrls } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../molecule/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  localStorageKeys,
  useLocalStorage,
} from "../../utils/localStorageItems";
import SocialLogin from "../../organism/SocialLogin";
import TextField from "@mui/material/TextField";
import btnBackNoBackground from "../../assets/btnBackNoBackground.svg";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const SignUp = () => {
  const nav = useNavigate();
  const signUp = useSignUp();
  const logIn = useLogin();
  const local_storage = useLocalStorage();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTooltipWeb, setOpenTooltipWeb] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event) => {
    event.stopPropagation();

    setOpen(true);
  };

  const handleTooltipCloseWeb = () => {
    setOpenTooltipWeb(false);
  };

  const handleTooltipOpenWeb = (event) => {
    event.stopPropagation();

    setOpenTooltipWeb(true);
  };

  const phoneRegExp = /^\+?\(?([0-9]{3})\)?[ .-]?([0-9]{3})[ .-]?([0-9]{4})$/;
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please enter your name."),
      email: Yup.string()
        .required("Please enter your email")
        .email("Please enter a valid email"),
      phone: Yup.string()
        .required("Please enter your phone number.")
        .matches(phoneRegExp, "Please enter a valid phone number"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.()\-_+=<>?])[A-Za-z\d!@#$%^&*.()\-_+=<>?]{8,}$/,
          "Password must contain at least 8 characters, one capital letter, one number and one special character (!@#$%^&*.()-_+=<>?)",
        ),
      confirmPassword: Yup.string()
        .required("Please re-enter your password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));

      let data = {
        name: values.name,
        email: values.email,
        phone_number: values.phone,
        password: values.password,
      };
      setLoading(true);
      signUp.mutate(data, {
        onSuccess: (response) => {
          console.log("sign up response:", response);
          logIn.mutate(
            { username: values.email, password: values.password },
            {
              onSuccess: (response) => {
                setLoading(false);
                console.log("login response:", response);
                local_storage.setLocalStorageItem(
                  localStorageKeys.accessToken,
                  response?.data?.token,
                );
                local_storage.setLocalStorageItem(
                  localStorageKeys.is_pre_appointment_filled,
                  response?.data?.is_pre_appointment_filled,
                );
                local_storage.setLocalStorageItem(
                  localStorageKeys.user,
                  JSON.stringify(response?.data?.user),
                );
                nav(routeUrls.signupSubscription);
              },
              onError: (err) => {
                setLoading(false);
                toast.error(
                  err?.response?.data?.error_message ||
                    "Something went wrong. Please try again.",
                );
              },
            },
          );

          // local_storage.setLocalStorageItem(localStorageKeys.name, response?.data?.name)
        },
        onError: (err) => {
          setLoading(false);
          toast.error(
            err?.response?.data?.error_message ||
              "Something went wrong. Please try again.",
          );
        },
      });
    },
  });

  const backButtonClick = () => {
    nav(routeUrls.landingPage);
  };

  return (
    <>
      <Loader open={loading} />
      <SignupPageLayout backButtonClick={backButtonClick}>
        <Grid
          item
          sm={4}
          sx={{
            backgroundColor: "#053D70",
            borderRadius: `24px 24px 0px 0px`,
            paddingLeft: { xs: `${scaleByRatio(49)}px`, xl: `49px` },
            paddingRight: { xs: `${scaleByRatio(49)}px`, xl: `49px` },
            paddingTop: { xs: `${scaleByRatio(24)}px`, xl: `24px` },
            display: { xs: `none`, sm: `block` },
          }}
        >
          <Grid
            item
            xs={12}
            justifyContent={"left"}
            alignItems={"start"}
            sx={{
              marginBottom: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
            }}
          >
            <Typography
              className={styles.signUpHeading}
              sx={{
                flexGrow: 1,
                fontSize: { xs: `${scaleByRatio(28)}px`, xl: `28px` },
              }}
            >
              SIGN UP
            </Typography>
          </Grid>
          <FormikProvider value={formik}>
            <form>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                  }}
                >
                  NAME
                </Typography>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  placeholder="Please enter your full name"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  sx={{
                    minHeight: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                    fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                  }}
                />
                {/* <Field
                  type="text"
                  name="name"
                  placeholder="Please enter your full name"
                  handleChange={formik.handleChange}
                  value={formik.values.name}
                  className={styles.inputBox}
                  multiline={false}
                  style={{
                    minHeight: `40px`,
                    fontSize: `${scaleByRatio(14)}px`,
                  }}
                />
                <span className={styles.txtError}>
                  <ErrorMessage name={"name"} />
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                  }}
                >
                  EMAIL
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Please enter your email"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.email}
                  onChange={(e) => {
                    const value = e.target.value || "";
                    formik.setFieldValue("email", value.toLowerCase());
                  }} //{formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{
                    minHeight: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                    fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                  }}
                />
                {/* <Field
                  type="text"
                  name="email"
                  placeholder="Please enter your email"
                  handleChange={formik.handleChange}
                  value={formik.values.email}
                  className={styles.inputBox}
                  multiline={false}
                  style={{
                    minHeight: `${scaleByRatio(40)}px`,
                    fontSize: `${scaleByRatio(14)}px`,
                  }}
                />
                <span className={styles.txtError}>
                  <ErrorMessage name={"email"} />
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                  }}
                >
                  PHONE
                </Typography>
                {/* <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  placeholder="Please enter your phone"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  sx={{
                    minHeight: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                    fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                  }}
                /> */}
                <PhoneInput
                  country={"us"}
                  onlyCountries={["us"]}
                  placeholder={"Please enter your phone"}
                  value={formik.values.phone}
                  onChange={(phone) => formik.setFieldValue("phone", phone)}
                  onBlur={() => formik.setFieldTouched("phone", true)}
                  inputClass={styles.inputBox}
                  inputStyle={{
                    minHeight: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                    fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                  }}
                  disableCountryCode={true}
                />

                <Typography
                  sx={{
                    display: `${
                      formik.touched.phone && formik.errors.phone?.length > 0
                        ? "block"
                        : "none"
                    }`,
                    color: "#d32f2f",
                    fontWeight: "400",
                    fontSize: "0.75rem",
                    marginLeft: "14px",
                  }}
                >
                  {formik.errors.phone}
                </Typography>

                {/* <Field
                  type="text"
                  name="phone"
                  placeholder="Please enter your phone"
                  handleChange={formik.handleChange}
                  value={formik.values.phone}
                  className={styles.inputBox}
                  multiline={false}
                  style={{
                    minHeight: `${scaleByRatio(40)}px`,
                    fontSize: `${scaleByRatio(14)}px`,
                  }}
                />
                <span className={styles.txtError}>
                  <ErrorMessage name={"phone"} />
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                  }}
                >
                  PASSWORD
                  <IconButton
                    sx={{
                      color: "#FFFFFF",
                      fontSize: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                      padding: "0",
                    }}
                    onClick={handleTooltipOpenWeb}
                  >
                    <ClickAwayListener onClickAway={handleTooltipCloseWeb}>
                      <Tooltip
                        open={openTooltipWeb}
                        onClose={handleTooltipCloseWeb}
                        disableHoverListener
                        arrow
                        placement="right-end"
                        title="Password must contain at least 8 characters, one capital letter, one number and one special character (!@#$%^&*.()-_+=<>?)"
                      >
                        <InfoOutlinedIcon
                          style={{ paddingLeft: "2px", paddingBottom: "1px" }}
                        />
                      </Tooltip>
                    </ClickAwayListener>
                  </IconButton>
                </Typography>
                {/* <div className={styles.showPassword}>
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    style={{
                      color: "#2563EB",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div> */}
                <TextField
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  id="password"
                  name="password"
                  placeholder="Please enter your password"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  sx={{
                    minHeight: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                    fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                    },
                    endAdornment: (
                      <div
                        style={{
                          // backgroundColor: "white",
                          borderRadius: "0%",

                          // padding: "4px",
                          // width: "300px",
                          // display: "flex",
                          // alignItems: "center",
                          // marginRight: "8px", // Adjust the margin as needed
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          style={{ color: "black", cursor: "pointer" }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                    ),
                  }}
                />
                {/* <Field
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Please enter your password"
                  handleChange={formik.handleChange}
                  value={formik.values.password}
                  className={styles.inputBox}
                  multiline={false}
                  style={{
                    minHeight: `${scaleByRatio(40)}px`,
                    fontSize: `${scaleByRatio(14)}px`,
                  }}
                />

                <span className={styles.txtError}>
                  <ErrorMessage name={"password"} />
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                  }}
                >
                  CONFIRM PASSWORD
                </Typography>

                <TextField
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Please re-enter your password"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  sx={{
                    minHeight: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                    fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                    // borderRadius: "8px",
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                    },
                    endAdornment: (
                      <div
                        style={{
                          // backgroundColor: "white",
                          borderRadius: "0%",

                          // padding: "4px",
                          // width: "300px",
                          // display: "flex",
                          // alignItems: "center",
                          // marginRight: "8px", // Adjust the margin as needed
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showConfirmPassword ? faEye : faEyeSlash}
                          style={{ color: "black", cursor: "pointer" }}
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      </div>
                    ),
                  }}
                />
                {/* <Field
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Please re-enter your password"
                  handleChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  className={styles.inputBox}
                  multiline={false}
                  style={{
                    minHeight: `${scaleByRatio(40)}px`,
                    fontSize: `${scaleByRatio(14)}px`,
                  }}
                />
                <span className={styles.txtError}>
                  <ErrorMessage name={"confirmPassword"} />
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"center"}
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <CustomButton
                    text={"SIGN UP"}
                    variant={ButtonVariant.contained}
                    type={ButtonType.custom}
                    onClick={formik.handleSubmit}
                  />
                </Typography>
              </Grid>
            </form>
          </FormikProvider>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            sx={{
              display: "flex",
              mt: 1,
            }}
          >
            <Typography
              textAlign="left"
              className={styles.agreement}
              sx={{
                fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
              }}
            >
              By signing up, you agree to our
            </Typography>
            <Typography
              textAlign="left"
              className={styles.agreement}
              sx={{
                color: `#FFC200`,
                fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                cursor: "pointer",
              }}
              onClick={() => {
                nav(routeUrls.termsAndConditions);
              }}
            >
              &nbsp;Terms and Conditions
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            sx={{
              display: "flex",
              mt: 2,
              mb: 2,
            }}
          >
            <Grid
              item
              xs={9}
              justifyContent={"center"}
              sx={{
                display: "flex",
                borderTop: "1px solid #FFFFFF",
              }}
            >
              <Typography
                fullWidth
                textAlign="center"
                className={styles.agreement}
                sx={{
                  color: "#FFFFFF",
                  position: "absolute",
                  marginTop: { xs: "-0.45rem", xl: "-0.75rem" },
                  backgroundColor: "#053D70",
                  fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                }}
              >
                &nbsp;Or signup with&nbsp;
              </Typography>
              {!isMobile && <SocialLogin />}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#053D70",
            borderRadius: `24px 24px 0px 0px`,
            paddingLeft: `20px`,
            paddingRight: `20px`,
            paddingTop: `24px`,
            marginTop: `40px`,
            marginBottom: "20px",
            display: { xs: "block", sm: "none" },
          }}
        >
          <Grid
            item
            xs={12}
            justifyContent={"left"}
            alignItems={"start"}
            sx={{
              marginBottom: `16x`,
            }}
          >
            <Typography
              className={styles.signUpHeading}
              sx={{
                flexGrow: 1,
                fontSize: `28px`,
              }}
            >
              <Box
                component="img"
                src={btnBackNoBackground}
                alt="back"
                className={styles.appIcon}
                sx={{
                  height: { xs: "32px" },
                  width: { xs: "32px" },
                  cursor: "pointer",
                  paddingTop: "7px",
                }}
                onClick={backButtonClick}
              />
              &nbsp;SIGN UP
            </Typography>
          </Grid>
          <FormikProvider value={formik}>
            <form>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: `16px`,
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: `16px`,
                  }}
                >
                  NAME
                </Typography>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  placeholder="Please enter your full name"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  sx={{
                    minHeight: { xs: `40px` },
                    fontSize: { xs: `14px` },
                  }}
                />
                {/* <Field
                  type="text"
                  name="name"
                  placeholder="Please enter your full name"
                  handleChange={formik.handleChange}
                  value={formik.values.name}
                  className={styles.inputBox}
                  multiline={false}
                  style={{
                    minHeight: `40px`,
                    fontSize: `14px`,
                    paddingLeft: "8px",
                  }}
                />
                <span className={styles.txtError}>
                  <ErrorMessage name={"name"} />
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: `16px`,
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: `16px`,
                  }}
                >
                  EMAIL
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Please enter your email"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.email}
                  onChange={(e) => {
                    const value = e.target.value || "";
                    formik.setFieldValue("email", value.toLowerCase());
                  }} //{formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  sx={{
                    minHeight: { xs: `40px` },
                    fontSize: { xs: `14px` },
                    textTransform: "lowercase",
                  }}
                  style={{
                    textTransform: "lowercase",
                  }}
                />
                {/* <Field
                  type="text"
                  name="email"
                  placeholder="Please enter your email"
                  handleChange={formik.handleChange}
                  value={formik.values.email}
                  className={styles.inputBox}
                  multiline={false}
                  style={{
                    minHeight: `40px`,
                    fontSize: `14px`,
                    paddingLeft: "8px",
                  }}
                />
                <span className={styles.txtError}>
                  <ErrorMessage name={"email"} />
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: `16px`,
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: `16px`,
                  }}
                >
                  PHONE
                </Typography>
                {/* <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  placeholder="Please enter your phone"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  sx={{
                    minHeight: { xs: `40px` },
                    fontSize: { xs: `14px` },
                  }}
                /> */}
                <PhoneInput
                  country={"us"}
                  onlyCountries={["us"]}
                  placeholder={"Please enter your phone"}
                  value={formik.values.phone}
                  onChange={(phone) => formik.setFieldValue("phone", phone)}
                  onBlur={() => formik.setFieldTouched("phone", true)}
                  inputClass={styles.inputBox}
                  inputStyle={{
                    minHeight: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                    fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                  }}
                  disableCountryCode={true}
                />

                <Typography
                  sx={{
                    display: `${
                      formik.touched.phone && formik.errors.phone?.length > 0
                        ? "block"
                        : "none"
                    }`,
                    color: "#d32f2f",
                    fontWeight: "400",
                    fontSize: "0.75rem",
                    marginLeft: "14px",
                  }}
                >
                  {formik.errors.phone}
                </Typography>
                {/* <Field
                  type="text"
                  name="phone"
                  placeholder="Please enter your phone"
                  handleChange={formik.handleChange}
                  value={formik.values.phone}
                  className={styles.inputBox}
                  multiline={false}
                  style={{
                    minHeight: `40px`,
                    fontSize: `14px`,
                    paddingLeft: "8px",
                  }}
                />
                <span className={styles.txtError}>
                  <ErrorMessage name={"phone"} />
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: `16px`,
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: `16px`,
                  }}
                >
                  PASSWORD
                  {/* <Tooltip
                    arrow
                    placement="right-start"
                    title="Password must contain at least 8 characters, one capital letter, one number and one special character (!@#$%^&*.())"
                  > */}
                  <IconButton
                    sx={{
                      color: "#FFFFFF",
                      fontSize: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
                      padding: "0",
                    }}
                    onClick={handleTooltipOpen}
                  >
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        disableHoverListener
                        arrow
                        placement="top"
                        title="Password must contain at least 8 characters, one capital letter, one number and one special character (!@#$%^&*.()-_+=<>?)"
                        open={open}
                        onClose={handleTooltipClose}
                      >
                        {/* <div> */}
                        <InfoOutlinedIcon
                          style={{ paddingLeft: "2px", paddingBottom: "1px" }}
                        />
                        {/* </div> */}
                      </Tooltip>
                    </ClickAwayListener>
                  </IconButton>
                </Typography>
                <TextField
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  id="password"
                  name="password"
                  placeholder="Please enter your password"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  sx={{
                    minHeight: { xs: `40px` },
                    fontSize: { xs: `14px` },
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                    },
                    endAdornment: (
                      <div
                        style={{
                          // backgroundColor: "white",
                          borderRadius: "0%",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          style={{ color: "black", cursor: "pointer" }}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                    ),
                  }}
                />
                {/* <Field
                  component={PasswordTextField}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Please enter your password"
                  handleChange={formik.handleChange}
                  value={formik.values.password}
                  className={styles.inputBox}
                  multiline={false}
                  style={{
                    minHeight: `40px`,
                    fontSize: `14px`,
                    paddingLeft: "8px",
                  }}
                />

                <span className={styles.txtError}>
                  <ErrorMessage name={"password"} />
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"start"}
                sx={{
                  marginBottom: `40px`,
                }}
              >
                <Typography
                  className={styles.label}
                  sx={{
                    flexGrow: 1,
                    fontSize: `16px`,
                  }}
                >
                  CONFIRM PASSWORD
                </Typography>
                <TextField
                  type={showConfirmPassword ? "text" : "password"}
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Please re-enter your password"
                  label=""
                  className={styles.inputBox}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  sx={{
                    minHeight: { xs: `${scaleByRatio(40)}px`, xl: `40px` },
                    fontSize: { xs: `${scaleByRatio(14)}px`, xl: `14px` },
                    // borderRadius: "8px",
                  }}
                  InputProps={{
                    style: {
                      backgroundColor: "white",
                    },
                    endAdornment: (
                      <div
                        style={{
                          // backgroundColor: "white",
                          borderRadius: "0%",

                          // padding: "4px",
                          // width: "300px",
                          // display: "flex",
                          // alignItems: "center",
                          // marginRight: "8px", // Adjust the margin as needed
                        }}
                      >
                        <FontAwesomeIcon
                          icon={showConfirmPassword ? faEye : faEyeSlash}
                          style={{ color: "black", cursor: "pointer" }}
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                      </div>
                    ),
                  }}
                />

                {/* <Field
                  component={PasswordTextField}
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Please re-enter your password"
                  handleChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  className={styles.inputBox}
                  multiline={false}
                  style={{
                    minHeight: `40px`,
                    paddingLeft: "8px",

                    fontSize: `14px`,
                  }}
                />
                <span className={styles.txtError}>
                  <ErrorMessage name={"confirmPassword"} />
                </span> */}
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"center"}
                sx={{
                  display: "flex",
                }}
              >
                <Typography
                  textAlign="center"
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <CustomButton
                    text={"SIGN UP"}
                    variant={ButtonVariant.contained}
                    type={ButtonType.custom}
                    onClick={formik.handleSubmit}
                  />
                </Typography>
              </Grid>
            </form>
          </FormikProvider>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            sx={{
              display: "flex",
              mt: 1,
            }}
          >
            <Typography
              textAlign="left"
              className={styles.agreement}
              style={{
                fontSize: `14px`,
              }}
            >
              By signing up, you agree to our
              <span
                style={{
                  color: `#FFC200`,
                  fontSize: `14px`,
                  cursor: "pointer",
                }}
                onClick={() => {
                  nav(routeUrls.termsAndConditions);
                }}
              >
                &nbsp;Terms and Conditions
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent={"center"}
            sx={{
              display: "flex",
              mt: 2,
              mb: 2,
            }}
          >
            <Grid
              item
              xs={9}
              justifyContent={"center"}
              sx={{
                display: "flex",
                borderTop: "1px solid #FFFFFF",
              }}
            >
              <Typography
                fullWidth
                textAlign="center"
                className={styles.agreement}
                style={{
                  color: "#FFFFFF",
                  position: "absolute",
                  marginTop: "-0.45rem",
                  backgroundColor: "#053D70",
                  fontSize: `14px`,
                }}
              >
                &nbsp;Or signup with&nbsp;
              </Typography>
              {isMobile && <SocialLogin />}
            </Grid>
          </Grid>
        </Grid>
      </SignupPageLayout>
    </>
  );
};

export default SignUp;
