import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { apiUrls } from '../../utils/constants';
import axios from 'axios';
import Header from '../../organism/header';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ViewArticle = () => {
    const location = useLocation();
    const dataFromPreviousComponent = location.state;
    let showBack = false;
    if(dataFromPreviousComponent){
        showBack = dataFromPreviousComponent.showBack
    }

    console.log(showBack)
    const navigate = useNavigate();
    const { id } = useParams();
    console.log(id);
    const [articleData, setArticleData] = useState(null);
    const [articleContent, setArticleContent] = useState(null);
    const [uploadedArticleImageURL, setUploadedArticleImageURL] = useState(null);
    const [sectionImageURLs, setSectionImageURLs] = useState([]);
    const [imagesLoaded, setImagesLoaded] = useState(false)

    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrls.article + id + "/");
            setArticleData(response.data);
        } catch (error) {
            console.error('Error fetching article:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        console.log(articleData);
        if (articleData) {
            setArticleContent(JSON.parse(articleData.content));
            console.log(articleContent);
        }
    }, [articleData]);

    useEffect(() => {
        if (articleContent) {// Fetch uploaded article image URL
            axios.get(apiUrls.articleImage + articleContent.uploadedArticleImageURL + '/')
                .then(response => {
                    setUploadedArticleImageURL(response.data.image);
                })
                .catch(error => {
                    console.error('Error fetching uploaded article image:', error);
                });

            // Fetch section image URLs
            const promises = articleContent.sectionImageURLs.map(imageID =>
                axios.get(apiUrls.articleImage + imageID + '/')
                    .then(response => response.data.image)
                    .catch(error => {
                        console.error('Error fetching section image:', error);
                        return null;
                    })
            );

            Promise.all(promises)
                .then(urls => {
                    setSectionImageURLs(urls);
                });
            setImagesLoaded(true);
        }
    }, [articleContent])


    return (
        <>
            <Header />
            <div className='formContainer'>
                {articleContent && imagesLoaded ? (
                    <>
                        {showBack && (
                            <Button onClick={() => navigate(-1)} style={{ color: 'blue', left: '0px' }}>&#8592; Back</Button>
                        )}
                        <div className='categoryHeading centerAlignedContainer'>{articleData.category.toUpperCase()}</div>
                        <br />
                        <div>
                            <div className='centerAlignedContainer'>
                                {uploadedArticleImageURL && <img src={uploadedArticleImageURL} alt="Article Image" className='articleImage' />}
                                <div className='articleTitle'>
                                    {articleContent.title}
                                </div>
                                <div className='articleTitleSub'>
                                    {articleContent.titleSub}
                                </div>
                            </div>
                            <div className='articleParagraph yellowLine'>
                                <p>{articleContent.intro}</p>
                            </div>
                            {articleContent.section.map((section, index) => (
                                <div key={index}>
                                    <div className='articleHeading'>
                                        {section.heading}
                                    </div>
                                    <div className='articleParagraph'>
                                        {section.contentType === 'paragraph' ? section.content.split('\n').map(item => <p key={item}>{item}</p>) : <ul className='articleList'>{section.content.split('\n').map(item => <li key={item}>{item}</li>)}</ul>}
                                    </div>
                                    {sectionImageURLs[index] && <img src={sectionImageURLs[index]} alt="Section Image" className='sectionImage' />}
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </>
    )
}

export default ViewArticle