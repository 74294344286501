import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import styles from "./index.module.scss";
import Header from "../../organism/header";
import Footer from "../../organism/footer";
import LandingPageHeader from "../../Components/LandingPageHeader";

const PageLayout = (props) => {
  return (
    <>
      <LandingPageHeader customStyle={{ marginBottom: "-4rem" }} />
      <Box component="main" className={styles.main} sx={{ flexGrow: 1 }}>
        {props.children}
      </Box>

      <Footer />
    </>
  );
};

export default PageLayout;
