import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import SignupPageLayout from "../../Layout/SignupPageLayout";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { scaleByRatio } from "../../utils/helper";
import btnBack from "../../assets/btnBack.svg";
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from "../../atoms/CustomButton";
import { routeUrls } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../molecule/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  localStorageKeys,
  useLocalStorage,
} from "../../utils/localStorageItems";
import SubscriberImg from "../../assets/cuate.svg";
import CheckSVG from "../../assets/check.svg";

import DollarSVG from "../../assets/attach-money.svg";
import Button from "../../Components/Button";
import { 
  useGetSubscriptionPlanDetails,
  usePostBuySubscription
} from "../../services/mutations";

import Payment from '../../organism/Payment'

const SignupSubscription = () => {
  const nav = useNavigate();
  const postBuySubscription = usePostBuySubscription()
  const local_storage = useLocalStorage();
  const [loading, setLoading] = useState(false);
  const subscriptionDetails = useGetSubscriptionPlanDetails();
  const [planDetail, setPlanDetail] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);

  useEffect(() => {
    setLoading(true)
    subscriptionDetails.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log(response);
          const data = response?.data;
          setPlanDetail(data?.result[0]);
          console.log(planDetail);
          setLoading(false)
        },
        onError: (err) => {
          setLoading(false);
          toast.error(
            err?.response?.data?.error_message ||
              "Something went wrong. Please try again."
          );
        },
      }
    );
  }, []);

  // useEffect(()=>{
  //   if(planDetail){
  //     buySubscription()
  //   }
  // },[planDetail])

  const buySubscription = ()=>{
    let data = {
      'price_tier':planDetail?.price_id
    }
    setLoading(true)
    postBuySubscription.mutate(data,{
      onSuccess:(response)=>{
        console.log('postBuySubscription success: ', response)
        setPaymentIntent(response?.data?.paymentIntent)
        setLoading(false)
      },
      onError: (err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.error_message ||
            "Something went wrong. Please try again."
        );
      },
    })
  }
  const handleCancelPayment = ()=>{
    setPaymentIntent(null)
  }
  const backButtonClick = () => {
    nav(routeUrls.landingPage);
  };

  return (
    <>
      <Loader open={loading} />
      <SignupPageLayout backButtonClick={backButtonClick}>
        <Grid
          container
          justifyContent="center"
          xs={12}
          sm={4}
          sx={{
            backgroundColor: "#053D70",
            borderRadius: `24px 24px 0px 0px`,
            paddingLeft: { xs: `${scaleByRatio(49)}px`, xl: `49px` },
            paddingRight: { xs: `${scaleByRatio(49)}px`, xl: `49px` },
            paddingTop: { xs: `${scaleByRatio(24)}px`, xl: `24px` },
            // display: { xs: `none`, sm: `block` },
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent={"left"}
            alignItems={"start"}
            sx={
              {
                // marginBottom: { xs: `${scaleByRatio(16)}px`, xl: `16px` },
              }
            }
          >
            <Box sx={{ display: { xs: "inline", md: "none" } }}>
              <img
                src={btnBack}
                alt="App Icon"
                onClick={backButtonClick}
                style={{
                  height: "2rem",
                  width: "2rem",
                  cursor: "pointer",
                }}
              />
            </Box>
            <Typography
              className={styles.signUpHeading}
              display="inline"
              sx={{
                flexGrow: 1,
                fontSize: { xs: `${scaleByRatio(28)}px`, xl: `28px` },
              }}
            >
              SUBSCRIBE
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent={"left"}
            alignItems={"start"}
            sx={
              {
                // marginBottom: {
                //   xs: `16px`,
                //   sm: `${scaleByRatio(16)}px`,
                //   xl: `16px`,
                // },
              }
            }
          >
            <Typography
              pb={4}
              className={styles.pageDescription}
              sx={{
                flexGrow: 1,
                fontSize: {
                  xs: `20px`,
                  sm: `${scaleByRatio(20)}px`,
                  xl: `20px`,
                },
                lineHeight: {
                  xs: `32px`,
                  sm: `${scaleByRatio(32)}px`,
                  xl: `32px`,
                },
              }}
            >
              We invite you to subscribe to our platform to access all of our
              features.
            </Typography>
          </Grid>
          <Grid item pb={2}>
            <Grid
              container
              xs={12}
              justifyContent={"center"}
              alignItems={"start"}
              className={styles.subscriptionData}
              sx={{
                backgroundColor: "",
                marginBottom: {
                  xs: `16px`,
                  sm: `${scaleByRatio(16)}px`,
                  xl: `16px`,
                },
                paddingBottom: `10px`
              }}
            >
              <Grid
                item
                p={1}
                sx={{
                  width: "18.5625rem",
                  height: "17.5rem",
                  backgroundColor: "white",
                  // border: "40px",
                  borderRadius: "1.25rem",
                  // padding: "0rem, 5rem",
                  // boxShadow: "0px 8px 16px 0px #0000003D",
                }}
              >
                <Grid
                  pb={1}
                  container
                  sx={{ borderBottom: "2px solid #888888" }}
                >
                  <Grid item xs={5} sx={{ backgroundColor: "" }}>
                    <img
                      src={SubscriberImg}
                      alt="App Icon"
                      style={{
                        height: "6.25rem",
                        width: "6.620625rem",
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} pl={1}>
                    <Grid spacing={1.5} container sx={{ backgroundColor: "" }}>
                      <Grid item>
                        <Typography
                          sx={{
                            color: "#223C6F",
                            fontSize: "1.25rem",
                            fontWeight: 700,
                          }}
                        >
                          {planDetail?.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          spacing={1}
                          container
                          alignItems="center"
                          sx={{ backgroundColor: "" }}
                        >
                          <Grid pb={1} item xs={1}>
                            <img
                              src={DollarSVG}
                              alt="App Icon"
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                              }}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              textAlign="center"
                              pb={1}
                              pl={1}
                              sx={{
                                color: "#FFC200",
                                fontSize: "2rem",
                                fontWeight: 400,
                                lineHeight: "1rem",
                              }}
                            >
                              {planDetail?.price}
                            </Typography>{" "}
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              pl={5}
                              style={{
                                color: "#888888",
                                fontSize: "0.875rem",
                                fontWeight: 300,
                              }}
                            >
                              &nbsp;&nbsp;/{planDetail?.interval}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            color: "#888888",
                            fontSize: "0.625rem",
                            fontWeight: 400,
                            lineHeight: "0.75rem",
                          }}
                        >
                          *medication not included
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  // pt={3}
                  sx={{
                    backgroundColor: "",
                    width: "100%",
                    color: "#888888",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                >
                  <Grid
                    // container
                    pt={{ xs: 0, md: 3 }}
                    item
                    sx={{
                      backgroundColor: "",
                      // alignItems: "center",
                      // width: "100%",
                    }}
                  >
                    <Grid
                      container
                      // pt={{ xs: 0, md: 3 }}

                      sx={{ backgroundColor: "" }}
                    >
                      <Grid item>
                        <Box
                          component="img"
                          src={CheckSVG}
                          alt="check"
                          className={styles.appIcon}
                          sx={{
                            // height: {
                            //   xs: "50px",
                            //   sm: `${scaleByRatio(50)}px`,
                            //   xl: "50px",
                            // },
                            // width: {
                            //   xs: "52px",
                            //   sm: `${scaleByRatio(52)}px`,
                            //   xl: "52px",
                            // },
                            cursor: "pointer",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography display="inline">
                          Product offering type 1
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item>
                        <Box
                          component="img"
                          src={CheckSVG}
                          alt="check"
                          className={styles.appIcon}
                          sx={{
                            // height: {
                            //   xs: "100px",
                            //   sm: `${scaleByRatio(100)}px`,
                            //   xl: "100px",
                            // },
                            // width: {
                            //   xs: "105px",
                            //   sm: `${scaleByRatio(105)}px`,
                            //   xl: "105px",
                            // },
                            cursor: "pointer",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography display="inline">
                          Product offering type 2
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item>
                        <Box
                          component="img"
                          src={CheckSVG}
                          alt="check"
                          className={styles.appIcon}
                          sx={{
                            // height: {
                            //   xs: "100px",
                            //   sm: `${scaleByRatio(100)}px`,
                            //   xl: "100px",
                            // },
                            // width: {
                            //   xs: "105px",
                            //   sm: `${scaleByRatio(105)}px`,
                            //   xl: "105px",
                            // },
                            cursor: "pointer",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography display="inline">
                          Product offering type 3
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Box
                          component="img"
                          src={CheckSVG}
                          alt="check"
                          className={styles.appIcon}
                          sx={{
                            // height: {
                            //   xs: "100px",
                            //   sm: `${scaleByRatio(100)}px`,
                            //   xl: "100px",
                            // },
                            // width: {
                            //   xs: "105px",
                            //   sm: `${scaleByRatio(105)}px`,
                            //   xl: "105px",
                            // },
                            cursor: "pointer",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography display="inline">
                          Product offering type 4
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            justifyContent="center"
            sx={{ backgroundColor: "" }}
            pb={5}
            
          >
            <Button
              p
              label="Pay Up Front"
              variant="contained"
              sx={{
                width: "21.5625rem",
                backgroundColor: "#FFC200",
                borderRadius: "12.5rem",
                color: "#223C6F",
                fontSize: "1.25rem",
                fontWeight: "700",
                
              }}
              onClick={buySubscription}
            />
            {paymentIntent && (
              <Payment paymentIntent={paymentIntent} handleCancelPayment={handleCancelPayment}/>
            )}
          </Grid>
          
        </Grid>
        
      </SignupPageLayout>
    </>
  );
};

export default SignupSubscription;
