import React, { useEffect, useState } from "react";
import Header from "../../organism/header";
import axios from "axios";
// import TableArticle from "./tableArticle"
import { apiUrls, NEWSAPI_URL } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

import "./styles.css";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const NewsList = () => {
  const navigate = useNavigate();

  const searchRef = React.createRef();
  const [articleData, setArticleData] = useState(null);
  const [loading, setLoading] = useState(false);
  const pageSize = 12; // Define page size

  const [searchCriteria, setSearchCriteria] = useState({
    text: "health",
    page: 1,
  });

  const fetchData = async (pageNumber = 1) => {
    try {
      setLoading(true);
      const response = await axios.get(
        NEWSAPI_URL.replace("q=", `q=${searchCriteria.text}`),
        {
          params: {
            page: pageNumber,
            pageSize: pageSize,
          },
        },
      );
      if (response.data) {
        setArticleData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Initial fetch with default page number
    setSearchCriteria((prev) => ({ ...prev, page: 1 }));
  }, []);

  useEffect(() => {
    fetchData(searchCriteria.page);
  }, [searchCriteria]);

  const handlePageChange = (event, value) => {
    setSearchCriteria((prev) => ({ ...prev, page: value }));
    // fetchData(value)
  };

  return (
    <>
      <Header />
      <Grid container alignItems={"center"} paddingBottom={5} paddingTop={10}>
        <Grid item xs={12} md={6} paddingLeft={10}>
          <Typography variant="h3" fontWeight={"bold"} component="h1">
            News Articles
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
          paddingLeft={{ xs: 10 }}
          paddingRight={10}
          paddingTop={{ xs: 2 }}
        >
          <TextField
            inputRef={searchRef}
            id="outlined-search"
            label="Search news"
            type="search"
            defaultValue={searchCriteria.text}
            InputProps={{
              endAdornment: (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setSearchCriteria({
                      text: searchRef?.current?.value,
                      page: 1,
                    });
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Go
                </Button>
              ),
            }}
            fullWidth={{ xs: true }}
          />
        </Grid>
      </Grid>
      <Grid container paddingLeft={10} paddingRight={10}>
        <Grid item xs={12} justifyContent={"flex-end"}>
          <Pagination
            count={10}
            color="primary"
            page={searchCriteria.page}
            onChange={handlePageChange}
            disabled={loading}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          />
        </Grid>
        {articleData && !!articleData?.articles && (
          <Grid
            container
            display={"flex"}
            spacing={4}
            style={{ opacity: loading ? 0.25 : 1 }}
          >
            {articleData?.articles
              .filter((item) => item.title !== "[Removed]")
              .map((articleItem, i) => (
                <Grid item xs={12} md={6} lg={4} key={i} display={"flex"}>
                  <Card
                    sx={{ minWidth: 250 }}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {!!articleItem?.urlToImage && (
                      <CardMedia
                        sx={{ height: 220 }}
                        backgroundColor={"#EEE"}
                        image={articleItem?.urlToImage}
                        title={articleItem?.title}
                        style={{ objectFit: "cover" }}
                      />
                    )}
                    <CardContent>
                      {!!articleItem?.source?.name && (
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          {articleItem?.source?.name}
                        </Typography>
                      )}
                      <Typography variant="h5" component="div">
                        {articleItem?.title}
                      </Typography>
                      {!!articleItem?.author && (
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          {articleItem?.author}
                        </Typography>
                      )}
                      <Typography variant="body2">
                        {articleItem?.description}
                      </Typography>
                    </CardContent>
                    <div style={{ flexGrow: 1 }}></div>
                    <CardActions>
                      <Button
                        size="small"
                        target={"_blank"}
                        href={articleItem?.url}
                      >
                        Learn More
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
        <Grid item xs={12} justifyContent={"flex-end"}>
          <Pagination
            count={50}
            color="primary"
            page={searchCriteria.page}
            onChange={handlePageChange}
            disabled={loading}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "50px",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NewsList;
